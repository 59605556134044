import { Action, Thunk, thunk, action } from 'easy-peasy';
import { login, collectionList, submitDataFinal, reportList, updateStudentProfileBasicInfo, updatData, updateStudentGuardianInfo, updateStudentAddressInfo, fetchDistrictList, fetchThanaList, saveStudentProfileUpdateToken, otpUsed, collectionListWithoutMonth, fetchpaidViews, login2 } from '../../http/auth';
import { loginUni, collectionListUni, submitDataFinalUni, reportListUni } from '../../http/authuni';
import { message } from 'antd';

const API_BASE = process.env.REACT_APP_API_ROOT
const API_BASE_UNI = process.env.REACT_APP_API_ROOT_UNIVERSITY
export interface Auth {
	user: any | undefined,
	checkAuth: Thunk<Auth>;
	authenticated: Action<Auth, any>;
	authenticate: Thunk<Auth, any>;
	authenticate2: Thunk<Auth, any>;
	authenticateMobile: Thunk<Auth, any>;
	authenticateUniversity: Thunk<Auth, any>;
	logout: Action<Auth, any>;
	loginFailed: Action<Auth, string>;
	error?: string;
	busy?: boolean;
	setBusy: Action<Auth, boolean>;
	collectionList: Thunk<Auth, any>;
	collectionListWithoutMonth: Thunk<Auth, any>;
	fetchpaidViews: Thunk<Auth, any>;
	collectionListUni: Thunk<Auth, any>;
	submitDataFinal: Thunk<Auth, any>;
	updateStudentProfileBasicInfo: Thunk<Auth, any>;
	updateStudentGuardianInfo: Thunk<Auth, any>;
	checkNumber: boolean;
	setcheckNumber: Action<Auth, boolean>;
	updateStudentGuardianInfo2: Thunk<Auth, any>;
	updateStudentAddressInfo: Thunk<Auth, any>;
	submitDataFinalUni: Thunk<Auth, any>;
	tableData: any,
	setTableData: Action<Auth, any>;	
	paidViews: any,
	setpaidViews: Action<Auth, any>;
	loading: boolean,
	startLoading: Action<Auth, any>;
	stopLoading: Action<Auth, any>;
	reportData: any;
	setreportData: Action<Auth, any>;
	reportList: Thunk<Auth, any>;
	reportListUni: Thunk<Auth, any>;
	serviceCharge: any;
	totalserviceCharge: any;
	setserviceCharge: Action<Auth, any>;
	settotalserviceCharge: Action<Auth, any>;
	checkType: any;
	setcheckType: Action<Auth, any>;

	districtList: any,
	thanaList: any,
	setdistrictList: Action<Auth, any>;
	setthanaList: Action<Auth, any>;
	districtListFetch: Thunk<Auth>;
	thanaListFetch: Thunk<Auth>;

	districtList2: any,
	thanaList2: any,
	setdistrictList2: Action<Auth, any>;
	setthanaList2: Action<Auth, any>;
	districtListFetch2: Thunk<Auth>;
	thanaListFetch2: Thunk<Auth>;
	hasOtp: boolean;
	sethasOtp: Action<Auth, boolean>;
	usedOtp: boolean;
	setusedOtp: Action<Auth, boolean>;
	saveStudentProfileUpdateToken: Thunk<Auth, any>;
	otpUsedSend: Thunk<Auth, any>;
}

export let token: string | undefined = undefined;

export const authStore: Auth = {
	user: undefined,
	checkType: undefined,
	serviceCharge: 0,
	totalserviceCharge: 0,
	tableData: [],
	paidViews: [],
	reportData: [],
	loading: false,
	checkAuth: thunk(async (actions) => {
		let jwt = localStorage.getItem("jwt");
		let type = localStorage.getItem("type");
		if (type == null || type == undefined) {
			return;
		} else {
			actions.setcheckType(JSON.parse(type));
		}
		if (jwt) {
			try {
				console.log("Success")
				actions.authenticated(JSON.parse(jwt));
			} catch (e) {
				console.error("Error");
			}
		}
	}),
	authenticate: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.setcheckType(payload.type);
		const response = await login(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//sessionStorage.setItem("jwt", JSON.stringify(body?.item));
			// console.log(body)
			if (body.messageType === 1) {
				if (payload.remember) {
					localStorage.setItem("jwt", JSON.stringify(body?.item));
				} else {
					localStorage.removeItem("jwt");
				}

				actions.authenticated(body?.item);

			} else {
				actions.loginFailed("Failed to login");
			}

		} else {
			actions.loginFailed("Failed to login");
		}
	}),
	authenticateMobile: thunk(async (actions, payload) => {
		actions.setcheckType(payload.type);
		const response = await login2(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType === 1) {
				if (payload.remember) {
					localStorage.setItem("type", JSON.stringify('school'));
					localStorage.setItem("jwt", JSON.stringify(body?.item));
				} else {
					localStorage.removeItem("jwt");
				}

				actions.authenticated(body?.item);

			} else {
				actions.loginFailed("Failed to login");
			}

		} else {
			actions.loginFailed("Failed to login");
		}
	}),
	authenticateUniversity: thunk(async (actions, payload) => {
		actions.setcheckType(payload.type);
		const response = await loginUni(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//sessionStorage.setItem("jwt", JSON.stringify(body?.item));
			// console.log(body)
			if (body.messageType === 1) {
				if (payload.remember) {
					localStorage.setItem("jwt", JSON.stringify(body?.item));
				} else {
					localStorage.removeItem("jwt");
				}

				actions.authenticated(body?.item);

			} else {
				actions.loginFailed("Failed to login");
			}

		} else {
			actions.loginFailed("Failed to login");
		}
	}),
	authenticated: action((state, auth) => {
		//console.log(auth)
		state.user = auth;
	}),

	setcheckType: action((state, payload) => {
		//console.log(auth)
		state.checkType = payload;
	}),
	setBusy: action((state, isbusy) => {
		state.busy = isbusy;
	}),
	loginFailed: action((state, message) => {
		state.busy = false;
		state.error = message;
	}),
	logout: action((state) => {
		localStorage.removeItem("jwt");
		localStorage.removeItem("type");
		localStorage.clear();
		sessionStorage.removeItem("jwt");
		state.user = undefined;
		state.busy = false;
		state.error = "";
		window.location.reload()
	}),

	collectionList: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await collectionList(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			actions.stopLoading("stop");
			const body = await response.json();
			//	console.log(body)
			if (body?.item?.length === 0) {
				message.warning('No Data Found');
				actions.setTableData([])
				actions.setserviceCharge(0)
			} else if (body?.item === null) {
				message.warning(body?.message);
				actions.setTableData([])
				actions.setserviceCharge(0)
			} else {
				actions.setTableData(body?.item)
				actions.setserviceCharge(body?.serviceCharge)
			}

		} else {
			message.error('Something Went Wrong');
			actions.stopLoading("stop");
			//actions.loginFailed("Failed to login");
		}
	}),	
	collectionListWithoutMonth: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await collectionListWithoutMonth(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			actions.stopLoading("stop");
			const body = await response.json();
			//	console.log(body)
			if (body?.item?.length === 0) {
				message.warning('No Data Found');
				actions.setTableData([])
				actions.setserviceCharge(0)
				actions.settotalserviceCharge(0)
			} else if (body?.item === null) {
				message.warning(body?.message);
				actions.setTableData([])
				actions.settotalserviceCharge(0)
				actions.setserviceCharge(0)
			} else {
				actions.setTableData(body?.item)
				actions.setserviceCharge(body.serviceChargeUnit)
				actions.settotalserviceCharge(body.totalPayableServiceCharge)
				// console.log(body?.totalPayableServiceCharge)
			}

		} else {
			message.error('Something Went Wrong');
			actions.stopLoading("stop");
			//actions.loginFailed("Failed to login");
		}
	}),	
	fetchpaidViews: thunk(async (actions, payload) => {
		const response = await fetchpaidViews(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//	console.log(body)
			if (body?.item?.length === 0) {
				//message.warning('No Data Found');
				actions.setpaidViews([])
			} else if (body?.item === null) {
				//message.warning(body?.message);
				actions.setpaidViews([])
			} else {
				actions.setpaidViews(body?.item)
			}

		} else {
			message.error('Something Went Wrong');
			actions.stopLoading("stop");
			//actions.loginFailed("Failed to login");
		}
	}),
	collectionListUni: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await collectionListUni(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			actions.stopLoading("stop");
			const body = await response.json();
			//	console.log(body)
			if (body?.item?.length === 0) {
				message.warning('No Data Found');
				actions.setTableData([])
				actions.setserviceCharge(0)
			} else if (body?.item === null) {
				message.warning(body?.message);
				actions.setTableData([])
				actions.setserviceCharge(0)
			} else {
				actions.setTableData(body?.item)
				actions.setserviceCharge(body?.serviceCharge)
			}

		} else {
			message.error('Something Went Wrong');
			actions.stopLoading("stop");
			//actions.loginFailed("Failed to login");
		}
	}),
	submitDataFinal: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await submitDataFinal(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.stopLoading("stop");
			if (body?.messageType === 1) {
				let requestUrl = `${API_BASE}/public/goto/spg-payment/portal?sessiontoken=${body?.item}`
				window.open(requestUrl, '_self');
			} else {
				message.error('Something Went Wrong');
			}


		} else {
			message.error('Something Went Wrong');
			//actions.loginFailed("Failed to login");
			actions.stopLoading("stop");
		}
	}),
	submitDataFinalUni: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await submitDataFinalUni(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.stopLoading("stop");
			if (body?.messageType === 1) {
				let requestUrl = `${API_BASE_UNI}/public/goto/spg-payment/portal?sessiontoken=${body?.item}`
				window.open(requestUrl, '_self');
			} else {
				message.error('Something Went Wrong');
			}


		} else {
			message.error('Something Went Wrong');
			//actions.loginFailed("Failed to login");
			actions.stopLoading("stop");
		}
	}),
	setTableData: action((state, payload) => {
		//console.log(auth)
		state.tableData = payload;
	}),	
	
	setpaidViews: action((state, payload) => {
		//console.log(auth)
		state.paidViews = payload;
	}),
	setserviceCharge: action((state, payload) => {
		//console.log(auth)
		state.serviceCharge = payload;
	}),	
	
	settotalserviceCharge: action((state, payload) => {
		//console.log(auth)
		state.totalserviceCharge = payload;
	}),
	startLoading: action((state) => {
		//console.log(auth)
		state.loading = true;
	}),
	stopLoading: action((state) => {
		//console.log(auth)
		state.loading = false;
	}),
	reportList: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await reportList(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			actions.stopLoading("stop");
			const body = await response.json();
			// console.log(body)
			if (body?.item?.length === 0) {
				message.warning('No Data Found');
				actions.setreportData(body?.item)
			} else {
				let dt = body?.item.map(function (item, index) {
					return {
						key: index,
						customStudentId: item.customStudentId,
						feeHeads: item.feeHeads,
						feeInvoiceId: item.feeInvoiceId,
						feeSubHeads: item.feeSubHeads,
						masterId: item.masterId,
						paidAmount: item.paidAmount,
						paymentDate: item.paymentDate,
						paymentStatus: item.paymentStatus,
						studentName: item.studentName,
						studentRoll: item.studentRoll
					}
				})
				actions.setreportData(dt)
			}

		} else {
			message.error('Something Went Wrong');
			actions.stopLoading("stop");
			//actions.loginFailed("Failed to login");
		}
	}),
	reportListUni: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await reportListUni(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			actions.stopLoading("stop");
			const body = await response.json();
			// console.log(body)
			if (body?.item === null) {
				message.warning('No Data Found');
				actions.setreportData(body?.item)
			} else {

				actions.setreportData(body?.item)
			}

		} else {
			message.error('Something Went Wrong');
			actions.stopLoading("stop");
			//actions.loginFailed("Failed to login");
		}
	}),
	setreportData: action((state, payload) => {
		//console.log(auth)
		state.reportData = payload;
	}),

	updateStudentProfileBasicInfo: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await updateStudentProfileBasicInfo(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.stopLoading("stop");
			if (body?.messageType === 1) {
				message.success(body?.message);
				actions.authenticate2(payload);
			} else {
				message.error('Something Went Wrong');
			}

		} else {
			message.error('Something Went Wrong');
			//actions.loginFailed("Failed to login");
			actions.stopLoading("stop");
		}
	}),

	updateStudentGuardianInfo: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await updateStudentGuardianInfo(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.stopLoading("stop");
			if (body?.messageType === 1) {
				message.success(body?.message);
				actions.authenticate2(payload);
			} else {
				message.error('Something Went Wrong');
			}

		} else {
			message.error('Something Went Wrong');
			//actions.loginFailed("Failed to login");
			actions.stopLoading("stop");
		}
	}),

	updateStudentAddressInfo: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await updateStudentAddressInfo(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.stopLoading("stop");
			if (body?.messageType === 1) {
				message.success(body?.message);
				actions.authenticate2(payload);
			} else {
				message.error('Something Went Wrong');
			}

		} else {
			message.error('Something Went Wrong');
			//actions.loginFailed("Failed to login");
			actions.stopLoading("stop");
		}
	}),
	authenticate2: thunk(async (actions, payload) => {
		const response = await updatData(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			// console.log(body)
			if (body.messageType === 1) {
				localStorage.setItem("jwt", JSON.stringify(body?.item));
				actions.authenticated(body?.item);

			} else {

			}

		} else {

		}
	}),

	districtList: null,
	thanaList: null,
	districtListFetch: thunk(async (actions) => {
		const response = await fetchDistrictList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setdistrictList(body?.item);
		} else {
			//const body = await response.json();
			//actions.loginFailed("Invalid Username/Password");
		}
	}),
	thanaListFetch: thunk(async (actions, id) => {
		const response = await fetchThanaList(id);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log('hello',body)
			actions.setthanaList(body?.item);
		} else {
			//const body = await response.json();
			//actions.loginFailed("Invalid Username/Password");
		}
	}),
	setdistrictList: action((state, payload) => {
		state.districtList = payload;
	}),
	setthanaList: action((state, payload) => {
		state.thanaList = payload;
	}),


	districtList2: null,
	thanaList2: null,
	districtListFetch2: thunk(async (actions) => {
		const response = await fetchDistrictList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setdistrictList2(body?.item);
		} else {
			//const body = await response.json();
			//actions.loginFailed("Invalid Username/Password");
		}
	}),
	thanaListFetch2: thunk(async (actions, id) => {
		const response = await fetchThanaList(id);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log('hello',body)
			actions.setthanaList2(body?.item);
		} else {
			//const body = await response.json();
			//actions.loginFailed("Invalid Username/Password");
		}
	}),
	setdistrictList2: action((state, payload) => {
		state.districtList2 = payload;
	}),
	setthanaList2: action((state, payload) => {
		state.thanaList2 = payload;
	}),
	checkNumber: false,
	setcheckNumber: action((state, payload) => {
		state.checkNumber = payload;
	}),

	updateStudentGuardianInfo2: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await updateStudentGuardianInfo(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.stopLoading("stop");
			if (body?.messageType === 1) {
				message.success(body?.message);
				actions.setcheckNumber(true);
				actions.authenticate2(payload);
			} else {
				message.error('Something Went Wrong');
			}

		} else {
			message.error('Something Went Wrong');
			//actions.loginFailed("Failed to login");
			actions.stopLoading("stop");
		}
	}),
	hasOtp: false,
	sethasOtp: action((state, payload) => {
		state.hasOtp = payload;
	}),
	usedOtp: false,
	setusedOtp: action((state, payload) => {
		state.usedOtp = payload;
	}),
	saveStudentProfileUpdateToken: thunk(async (actions, payload) => {
		const response = await saveStudentProfileUpdateToken(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				message.success(body?.message);
				actions.sethasOtp(true);
				//localStorage.setItem("otpCode", JSON.stringify(true));
			} else {
				message.error(body?.message);
				actions.sethasOtp(false);
			}

		} else {
			message.error('Something Went Wrong');
			actions.sethasOtp(false);
		}
	}),

	otpUsedSend: thunk(async (actions, payload) => {
		const response = await otpUsed(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				message.success(body?.message);
				actions.setusedOtp(true);
				localStorage.setItem("otpCode", JSON.stringify(true));
			} else {
				message.error(body?.message);
				actions.setusedOtp(false);
			}

		} else {
			message.error('Something Went Wrong');
			actions.setusedOtp(false);
		}
	}),
}
