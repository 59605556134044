import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Row, Select, Col, Button, Table, Input, Spin, Result, List, Card, Checkbox, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../store/hooks/easyPeasy';
import { delInvoice } from '../../http/auth';


export default function Payment(props) {
    const user = useStoreState(state => state.auth.user)
    const loading = useStoreState(state => state.auth.loading)
    const tableDatax = useStoreState(state => state.auth.tableData)
    const paidViews = useStoreState(state => state.auth.paidViews)
    const serviceCharge = useStoreState(state => state.auth.serviceCharge)
    const totalserviceCharge = useStoreState(state => state.auth.totalserviceCharge)
    const collectionList = useStoreActions(state => state.auth.collectionList);
    const collectionListWithoutMonth = useStoreActions(state => state.auth.collectionListWithoutMonth);
    const fetchpaidViews = useStoreActions(state => state.auth.fetchpaidViews);
    const submitDataFinal = useStoreActions(state => state.auth.submitDataFinal);
    const [form] = Form.useForm();

    // console.log(tableData)
    var isMobile = false; //initiate as false
    if (
        /iP(hone|od)|android.+mobile|BlackBerry|IEMobile/i.test(navigator.userAgent)
    ) {
        isMobile = true;
    }
    const [selectedRowKeys, setselectedRowKeys] = useState<any>([])
    const [pageTableData, setpageTableData] = useState<any>([])
    const [submitTableData, setsubmitTableData] = useState<any>([])
    const [tableData, settableData] = useState<any>([])
    const [totalPayable, settotalPayable] = useState<any>(0)
    const [totalCharge, settotalCharge] = useState<any>(0)
    const [charge, setcharge] = useState<any>(0)
    const [showcharge, setshowcharge] = useState<any>(0)

    useEffect(() => {
        settableData(tableDatax)
    }, [tableDatax])

    useEffect(() => {
        settotalCharge(totalserviceCharge)
    }, [totalserviceCharge])

    useEffect(() => {
        setcharge(serviceCharge)
    }, [serviceCharge])



    const onFinish = (values: any) => {
        // delInvoice({ identificationId: user?.identificationId });
        // console.log(user?.identificationId);
        setshowcharge(0);
        setcharge(0);
        settotalCharge(0);
        settotalPayable(0);
        let payload: any = {
            identificationId: user?.identificationId,
            instituteId: user?.instituteId,
            year: values?.year,
            months: ['all'],
        }
        //collectionList(payload);
        collectionListWithoutMonth(payload);
        fetchpaidViews(payload);
    };

    // console.log(paidViews)

    const currentyear = new Date().getFullYear();

    // console.log(currentyear)

    const optionsYear = [
        { value: currentyear - 1, label: currentyear - 1 },
        { value: currentyear, label: currentyear },
        { value: currentyear + 1, label: currentyear + 1 }
    ]

    const optionsMonth = [
        { value: "January", label: "January" },
        { value: "February", label: "February" },
        { value: "March", label: "March" },
        { value: "April", label: "April" },
        { value: "May", label: "May" },
        { value: "June", label: "June" },
        { value: "July", label: "July" },
        { value: "August", label: "August" },
        { value: "September", label: "September" },
        { value: "October", label: "October" },
        { value: "November", label: "November" },
        { value: "December", label: "December" }

    ]

    const columns = [
        {
            title: 'Fee Head Name',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
        },
        {
            title: 'Sub Head Name',
            dataIndex: 'feeSubHeadName',
            key: 'feeSubHeadName',
        },
        {
            title: 'Fee Amount',
            dataIndex: 'feeAmount',
            key: 'feeAmount',
        },
        {
            title: 'Fine Amount',
            dataIndex: 'fineAmount',
            key: 'fineAmount',
        },
        {
            title: 'Payable Amount',
            dataIndex: 'payableAmount',
            key: 'payableAmount',
        },
    ];
    const onSelectChange = (selectedRowKeys, value) => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setsubmitTableData(value);
        setselectedRowKeys(selectedRowKeys);

        let totalPayAbleVal = value
            .map(item => item.payableAmount)
            .reduce((prev, curr) => prev + curr, 0);
        settotalPayable(totalPayAbleVal)

    };
    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: onSelectChange,
    };

    // console.log(selectedRowKeys)

    const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

    const onPay = () => {
        let details = submitTableData.map(function (item) {
            return {
                "feeAmount": item?.feeAmount,
                "feeHeadId": item?.feeHeadId,
                "feeSubHeadId": item?.feeSubHeadId,
                "waiverAmount": item?.waiverAmount,
                "waiverId": item?.feeWaiverId,
                "fineAmount": item?.fineAmount
            }
        });
        // let details = tableData.map(function (item) {
        //     return {
        //         "feeAmount": item?.feeAmount,
        //         "feeHeadId": item?.feeHeadId,
        //         "feeSubHeadId": item?.feeSubHeadId,
        //         "waiverAmount": item?.waiverAmount,
        //         "waiverId": item?.feeWaiverId
        //     }
        // });
        let postData: any = {
            identificationId: user?.identificationId,
            serviceCharge: showcharge,
            details: details
        };
        submitDataFinal(postData)
    }

    const mobileSelect = (e, item) => {
        let temp;
        if (e) {
            temp = [...submitTableData, item]
            setsubmitTableData(temp)
            let totalPayAbleVal = temp
                .map(item => item.payableAmount)
                .reduce((prev, curr) => prev + curr, 0);
            settotalPayable(totalPayAbleVal)

        }
        if (!e) {
            temp = submitTableData.filter(i => i.feeSubHeadId !== item.feeSubHeadId);
            setsubmitTableData(temp)
            let totalPayAbleVal = temp
                .map(item => item.payableAmount)
                .reduce((prev, curr) => prev + curr, 0);
            settotalPayable(totalPayAbleVal)

        }

    }

    const onchangeValue: any =
        useCallback((i1, i2, payableList) => (e: React.ChangeEvent<HTMLInputElement>) => {

            const newData: any = [...tableData];
            newData[i1]['payableList'][i2]['selected'] = e.target.checked;
            // console.log(payableList?.length)
            // console.log(payableList?.filter(item=>item.selected===true)?.length)
            if (payableList?.filter(item => item.selected === true)?.length > 0 && payableList?.length !== payableList?.filter(item => item.selected === true)?.length) {
                newData[i1]['indeterminateSelected'] = true;
            } else if ((payableList?.length) === (payableList?.filter(item => item.selected === true)?.length)) {
                newData[i1]['indeterminateSelected'] = false;
                newData[i1]['selected'] = true;
            } else if (payableList?.filter(item => item.selected === true)?.length === 0) {
                newData[i1]['selected'] = false;
                newData[i1]['indeterminateSelected'] = false;
            };
            common(newData);
            settableData(newData);
        }, [tableData]);

    const onchangeValueAll: any =
        useCallback((i1) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData: any = [...tableData];
            if (e.target.checked === true) {
                newData[i1]['selected'] = true;
                newData[i1]['indeterminateSelected'] = false;
                newData[i1]['payableList'].map(item => {
                    item['selected'] = true;
                    return item;
                })
            } else {
                newData[i1]['selected'] = false;
                newData[i1]['indeterminateSelected'] = false;
                newData[i1]['payableList'].map(item => {
                    item['selected'] = false;
                    return item;
                })
            }
            common(newData);
            settableData(newData);

        }, [tableData]);

    function common(data) {

        let len = data.filter(item => item.selected === true || item.indeterminateSelected === true).length;
        let temp = data.filter(item => item.selected === true || item.indeterminateSelected === true)?.map(item => item.payableList.filter(item => item.selected === true)).flat()
        setsubmitTableData(temp);
        let totalPayAbleVal = temp.map(item => item.payableAmount)
            .reduce((prev, curr) => prev + curr, 0);
        settotalPayable(totalPayAbleVal);
        setshowcharge(len * charge > totalserviceCharge ? totalserviceCharge : len * serviceCharge);
    }

    // console.log(tableData)

    return (
        <div className={isMobile ? 'mobileView' : "mt-25 mb-sm-25"} >
            <Card title="Make Payment" extra={
                <Tooltip title="সদয় অবগতির জন্য জানানো যাচ্ছে যে, ফি পেমেন্ট করার সময় পেমেন্ট কনফার্মেশন ম্যাসেজ পাচ্ছেন না এমন সমস্যা হলে অনুগ্রহ করে প্রথমে ব্যালান্স চেক 
                করে নিশ্চিত হন টাকা কেটেছে কি না। যদি টাকা না কাটে তাহলে ১৫ মিনিট অপেক্ষা করুন এবং পুনুরায় ব্যালান্স চেক করে নিশ্চিত হন টাকা কেটেছে কি না। 
                টাকা কেটে নেওয়ার পর অনুগ্রহ করে দ্বিতীয়বার পেমেন্ট করবেন না। টাকা কেটেছে কিন্তু রিসিট ডাউনলোড করা যাচ্ছে না তাহলে 09612-191919 অথবা 01951-901919 নাম্বারে তাৎক্ষণিক যোগাযোগ করুন।">
                    <QuestionCircleOutlined />
                </Tooltip>
            }>
                <div className="loading" style={{ display: loading ? "inherit" : "none" }}> <Spin indicator={antIcon} /></div>
                <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} className="mb-sm-25" >
                    <Row gutter={15}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}></Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>

                            <Form.Item
                                name="year"
                                label="Year:"
                                className="title-Text custon-form-wrapper"
                                rules={[
                                    { required: true, message: "Please Select Year" },
                                ]}
                            >
                                <Select allowClear placeholder="Select Year" options={optionsYear} />
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={24} md={24} lg={8} xl={8}>

                            <Form.Item
                                name="month"
                                label="Month:"
                                className="title-Text custon-form-wrapper"
                                rules={[
                                    { required: true, message: "Please Select Month" },
                                ]}
                            >
                                <Select mode="multiple" allowClear placeholder="Select Month" options={optionsMonth} />


                            </Form.Item>

                        </Col> */}
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item className="mb-sm-0">
                                <Button type="primary" className="success-button" htmlType="submit" style={{ marginTop: isMobile ? 0 : 30, width: "100%", padding: 10 }}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {tableData.length > 0 &&
                    <>
                        {!isMobile &&
                            <Row gutter={20}>
                                <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                                    { <strong style={{ fontSize: 18, marginTop: 0, marginBottom: 0, display: "block" }}>List of payable fees: </strong> }
                                    <List
                                        itemLayout="vertical"
                                        dataSource={tableData}
                                        size="large"
                                        style={{ maxHeight: 350, overflowY: 'auto', borderRight:'1px solid #4D9706 ' }}
                                        renderItem={(item: any, index) => (
                                            <List.Item key={item.serial}>

                                                <List
                                                    itemLayout="vertical"
                                                    dataSource={item.payableList}
                                                    size="large"
                                                    header={<div style={{ display: "flex", justifyItems: "center", alignContent: "center", alignItems: 'center' }}>
                                                        <div>
                                                            <Checkbox indeterminate={item.indeterminateSelected} checked={item.selected} onChange={onchangeValueAll(index)} ></Checkbox>
                                                        </div>
                                                        <div style={{ marginLeft: 10, fontWeight: 'bold' }}>
                                                            {item.monthName}
                                                        </div>

                                                    </div>
                                                    }
                                                    renderItem={(value: any, index2) => (
                                                        <List.Item key={value.feeSubHeadId}>
                                                            <ul className="w3-ul w3-card-4 payment-fee-list no-boxshadows">
                                                                <li className="w3-bar" style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>

                                                                    <div className="w3-bar-item" style={{ paddingLeft: 0 }}>
                                                                        <div style={{ display: "flex", justifyItems: "center", alignContent: "center", alignItems: 'center' }}>
                                                                            <div>
                                                                                <Checkbox checked={value.selected} onChange={onchangeValue(index, index2, item.payableList)} ></Checkbox>
                                                                            </div>
                                                                            <div style={{ marginLeft: 15, display: 'grid' }}>
                                                                                <span >{value?.feeHeadName} - ({value?.feeSubHeadName})</span>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                    <div style={{ display: 'grid', textAlign: 'center' }}>
                                                                        <span style={{ fontWeight: "bold", fontSize: 18 }}> ৳{value?.payableAmount}</span>
                                                                    </div>

                                                                </li>
                                                            </ul>



                                                        </List.Item>
                                                    )}
                                                />


                                            </List.Item>
                                        )}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                    {<strong style={{ fontSize: 18, marginTop: 0, marginBottom: 0, display: "block" }}>Previously paid fees: </strong> }
                                    {paidViews.length > 0 ?
                                        <List
                                            itemLayout="vertical"
                                            dataSource={paidViews}
                                            size="large"
                                            style={{ maxHeight: 350, overflowY: 'auto' }}
                                            renderItem={(value: any, index2) => (
                                                <List.Item key={value.feeHeadId}>
                                                    <ul className="w3-ul w3-card-4 payment-fee-list no-boxshadows">
                                                        <li className="w3-bar" style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>

                                                            <div className="w3-bar-item" style={{ paddingLeft: 0 }}>
                                                                <div style={{ display: "flex", justifyItems: "center", alignContent: "center", alignItems: 'center' }}>
                                                                    <div style={{ marginLeft: 15, display: 'grid' }}>
                                                                        <span >{value?.feeHeadName} - ({value?.feeSubHeadName})</span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div style={{ display: 'grid', textAlign: 'center' }}>
                                                                <span style={{ fontWeight: "bold", fontSize: 18 }}> ৳{value?.paidAmount}</span>
                                                            </div>

                                                        </li>
                                                    </ul>



                                                </List.Item>
                                            )}
                                        /> :
                                        <div style={{
                                            height: 350,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <strong>No Record Found</strong>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        }
                        {isMobile &&
                            <List
                                itemLayout="vertical"
                                dataSource={tableData}
                                size="large"
                                style={{ maxHeight: 200, overflowY: 'auto' }}
                                renderItem={(item: any, index) => (
                                    <List.Item key={item.serial}>

                                        <List
                                            itemLayout="vertical"
                                            dataSource={item.payableList}
                                            size="large"
                                            header={<div style={{ display: "flex", justifyItems: "center", alignContent: "center", alignItems: 'center' }}>
                                                <div>
                                                    <Checkbox indeterminate={item.indeterminateSelected} checked={item.selected} onChange={onchangeValueAll(index)} ></Checkbox>
                                                </div>
                                                <div style={{ marginLeft: 10, fontWeight: 'bold' }}>
                                                    {item.monthName}
                                                </div>

                                            </div>
                                            }
                                            renderItem={(value: any, index2) => (
                                                <List.Item key={value.feeSubHeadId}>
                                                    <ul className="w3-ul w3-card-4 payment-fee-list no-boxshadows">
                                                        <li className="w3-bar" style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>

                                                            <div className="w3-bar-item" style={{ paddingLeft: 0 }}>
                                                                <div style={{ display: "flex", justifyItems: "center", alignContent: "center", alignItems: 'center' }}>
                                                                    <div>
                                                                        <Checkbox checked={value.selected} onChange={onchangeValue(index, index2, item.payableList)} ></Checkbox>
                                                                    </div>
                                                                    <div style={{ marginLeft: 15, display: 'grid' }}>
                                                                        <span >{value?.feeHeadName} - ({value?.feeSubHeadName})</span>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div style={{ display: 'grid', textAlign: 'center' }}>
                                                                <span style={{ fontWeight: "bold", fontSize: 18 }}> ৳{value?.payableAmount}</span>
                                                            </div>

                                                        </li>
                                                    </ul>



                                                </List.Item>
                                            )}
                                        />


                                    </List.Item>
                                )}
                            />
                        }
                        {isMobile &&
                            <>
                                <div className="payableWrapper">
                                    <div className="totalPayable">
                                        <div className="totalPayableTitle"> Total Payable</div>
                                        <h3 className="totalPayableCount">{totalPayable}</h3>
                                    </div>
                                    <div className="bg">
                                    </div>
                                </div>

                                <Button type="primary" className="mb-sm-25 success-button" style={{ width: isMobile ? "100%" : "100%", marginTop: isMobile ? 20 : 20, float: "right", padding: 10, height: "auto" }} disabled={totalPayable > 0 ? false : true} onClick={() => onPay()}>
                                    Pay Now
                                </Button>
                                <span style={{ marginTop: 10, fontSize: 10, marginBottom: -10 }}> </span>
                                <div className="payment-instruction-wrapper ml-0">
                                    <span className="text-highlight">*Marked fields are mandatory</span>
                                    <span>{showcharge} Taka Service Charge is also payable with Total Payable</span>
                                </div>
                            </>
                        }
                    </>
                }
            </Card>
            {/* <br /> */}
            {/* {tableData.length > 0 && !isMobile ? <span style={{ fontSize: 18, marginTop: 25, marginBottom: 25, display: "block" }}>List of fees: </span> : ''} */}
            {/* <Skeleton active /> */}

            {tableData.length > 0 &&
                <>
                    {!isMobile &&
                        <Row className='mt-30'>
                            <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                <div className="payment-instruction-wrapper">
                                    <span className="text-highlight">*Marked fields are mandatory</span>
                                    {totalPayable > 0 && <span>{showcharge} Taka Service Charge is also payable with Total Payable</span>}
                                    {/* <span>2. XYZ</span> */}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                <Row className='d-flex align-items-center justify-content-between payable-wrapper'>
                                    <Col span={12} style={{ paddingRight: "6px" }}>
                                        <Form.Item className='payment-input'>
                                            <span>Total Payable:</span>
                                            <Input placeholder="Total Payable" disabled value={totalPayable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ paddingLeft: "6px" }}>
                                        <Form.Item>
                                            <div className="mb-sm-25">
                                                <Button className='success-button' type="primary" id="mb-sm-25 demo" style={{ width: isMobile ? "100%" : "100%", marginTop: isMobile ? 20 : 25, float: "right", }} disabled={totalPayable > 0 ? false : true} onClick={() => onPay()}>
                                                    Pay
                                                </Button>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    }
                </>

            }
        </div>
    )
}
